<template>
  <section class="section">
    <div class="container">
      <div class="columns is-multiline is-variable is-6">
        <div class="column is-7-desktop is-full-touch">
          <div class="content">
            <h1>{{ $t('otherservices.contentTitle') }}</h1>
            <div class="has-text-justified">
              <p>{{ $t('otherservices.summary.0') }}</p>
              <p>{{ $t('otherservices.summary.1') }}</p>
              <p>
                <router-link
                    :to="{ name: 'contact' }"
                    class="has-text-weight-bold"
                >
                  {{ $t('form.content.contactUs') }}
                </router-link>
                {{ $t('otherservices.summary.2') }}
              </p>
              <ul class="fa-ul">
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.shareholderagreement') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.companyorassociationcontact') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.annualupdateservice') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.commercialcontracts') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.employmentcontracts') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.organizationofincorporatedcompanies') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.obtainingtaxnumbers') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.changingthenameofyourcompany') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.changeaddressofyourcompany') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.dissolutionsandliquidations') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.commercialleases') }}
                </li>
                <li><span class="fa-li"><b-icon pack="far" icon="check-circle"></b-icon></span>
                  {{ $t('otherservices.services.trusts') }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="column is-5-desktop is-full-touch">
          <figure class="image">
            <img src="/contract.svg" class="image--services" alt="">
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: this.$t('otherservices.title'),
        meta: [
          { name: 'description', content: this.$t('otherservices.description') }
        ]
      }
    }
  }
</script>
